const LABS_LIST = [
  {
    name: 'Virtual Staging',
    defaultMediaUrl: 'https://res.cloudinary.com/ft-bounty/image/upload/w_600/v1724080616/app-materials/homepage-cards/stage-your-place_pgtund.png',
    hoverMediaUrl: 'https://res.cloudinary.com/ft-bounty/image/upload/w_600/v1724085434/app-materials/homepage-cards/stage-your-place-hover_tuaxf1.png',
    icon: 'material-symbols:scene',
    description: 'Real Estate professional?  Upload an image of an empty room, and we will decorate it to perfectly suit your needs.',
    navigateUrl: '/labs/home-staging',
  },
  {
    name: 'Lighten up your product using lights animation',
    defaultMediaUrl:
      'https://res.cloudinary.com/ft-bounty/image/upload/c_crop,w_332,h_273/v1724080728/app-materials/homepage-cards/lighting-effects_li0iwz.png',
    hoverMediaUrl:
      'https://res.cloudinary.com/ft-bounty/image/upload/c_crop,w_332,h_273/v1724085521/app-materials/homepage-cards/lighting-effects-hover_znjfs8.png',
    icon: 'ph:lightning-fill',
    description: 'Enhance your images with visual effects, transforming them into vivid, dreamy pieces',
    navigateUrl: '/labs/cinemagraph',
  },
  {
    name: 'AI Graphic Design',
    defaultMediaUrl: 'https://res.cloudinary.com/ft-bounty/image/upload/w_600/v1724758051/app-materials/homepage-cards/txt-2-graphics-example2_ps8pda.jpg',

    hoverMediaUrl: 'https://res.cloudinary.com/ft-bounty/image/upload/w_600/v1724758050/app-materials/homepage-cards/txt-2-graphics-example1_uxxgpx.jpg',
    icon: 'uil:window-grid',
    description: 'From text to graphic design, bring your brief to life with our AI',
    navigateUrl: '/labs/text-to-graphic-design',
  },

  {
    name: 'Prop Generator',
    defaultMediaUrl: 'https://res.cloudinary.com/ft-bounty/image/upload/w_600/v1724757350/app-materials/homepage-cards/text2props-example-1_rldtqs.jpg',
    hoverMediaUrl: 'https://res.cloudinary.com/ft-bounty/image/upload/w_600/v1724759049/text2props-example-2_ay4h4q.jpg',
    icon: 'material-symbols:emoji-objects-outline',
    description: 'Need an image of specific object? Describe it, and we will create a transparent PNG for you',
    navigateUrl: '/labs/text-to-subject',
  },

  {
    name: 'Text to Image',
    defaultMediaUrl: 'https://res.cloudinary.com/ft-bounty/image/upload/w_600/v1724759255/txt-2-img-example-2_f5y9wq.jpg',
    hoverMediaUrl: 'https://res.cloudinary.com/ft-bounty/image/upload/w_600/v1724759255/txt-2-img-example-1_xwoqr5.jpg',
    icon: 'ph:pencil-fill',
    description: 'Need a scenery / background? Describe an image you want, and we will create it for you',
    navigateUrl: '/labs/text-to-image',
  },
  {
    name: 'Blend two images',
    defaultMediaUrl: 'https://res.cloudinary.com/ft-bounty/image/upload/w_600/v1727608160/bed_u5n1dk.jpg',
    hoverMediaUrl: 'https://res.cloudinary.com/ft-bounty/image/upload/w_600/v1727608250/bed-pink_pein6k.jpg',
    icon: 'material-symbols:blender-outline',
    description: 'Give us the content and the style you want and we will mix it up for you!',
    navigateUrl: '/labs/blend-two-images',
  },

  {
    name: 'Editor',
    defaultMediaUrl: 'https://res.cloudinary.com/ft-bounty/image/upload/c_fill,w_332,h_273/v1726668097/app-materials/homepage-cards/editor_nai4kc.jpg',
    hoverMediaUrl: 'https://res.cloudinary.com/ft-bounty/image/upload/c_fill,w_332,h_273/v1726667878/app-materials/homepage-cards/editor-hover_o9n9my.jpg',
    icon: 'streamline:edit-image-photo-solid',
    description: 'Edit your photos with easy-to-use tools. Perfect for simple adjustments and enhancements in no time!',
    navigateUrl: 'editor',
  },
];
export default LABS_LIST;
