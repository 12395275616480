/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type {
  Ack,
  Asset,
  AssetListing,
  AssetUpdateRequestPayload,
  CreateProductRequest,
  FindOneAssetByRequestParams,
  Product,
  ProductListing,
} from '../models';
import {
  AckFromJSON,
  AckToJSON,
  AssetFromJSON,
  AssetToJSON,
  AssetListingFromJSON,
  AssetListingToJSON,
  AssetUpdateRequestPayloadFromJSON,
  AssetUpdateRequestPayloadToJSON,
  CreateProductRequestFromJSON,
  CreateProductRequestToJSON,
  FindOneAssetByRequestParamsFromJSON,
  FindOneAssetByRequestParamsToJSON,
  ProductFromJSON,
  ProductToJSON,
  ProductListingFromJSON,
  ProductListingToJSON,
} from '../models';

export interface AssetsApiCreateAssetRequest {
  image: Blob;
  origin: CreateAssetOriginEnum;
  analyze_content?: boolean;
  segment_foreground?: boolean;
  id?: string;
}

export interface AssetsApiCreateProductOperationRequest {
  create_product_request?: CreateProductRequest;
}

export interface AssetsApiDeleteAssetRequest {
  id: string;
}

export interface AssetsApiFindOneAssetByRequest {
  find_one_asset_by_request_params?: FindOneAssetByRequestParams;
}

export interface AssetsApiListAssetsRequest {
  max_items?: string;
  cursor_serial?: string;
}

export interface AssetsApiProductSearchRequest {
  asset_id?: string;
  name?: string;
}

export interface AssetsApiProductShowRequest {
  id: string;
}

export interface AssetsApiSearchAssetsRequest {
  search_term: string;
}

export interface AssetsApiUpdateAssetRequest {
  id: string;
  asset_update_request_payload?: AssetUpdateRequestPayload;
}

/**
 *
 */
export class AssetsApi extends runtime.BaseAPI {
  /**
   */
  async createAssetRaw(
    requestParameters: AssetsApiCreateAssetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Asset>> {
    if (requestParameters.image === null || requestParameters.image === undefined) {
      throw new runtime.RequiredError('image', 'Required parameter requestParameters.image was null or undefined when calling createAsset.');
    }

    if (requestParameters.origin === null || requestParameters.origin === undefined) {
      throw new runtime.RequiredError('origin', 'Required parameter requestParameters.origin was null or undefined when calling createAsset.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.analyze_content !== undefined) {
      formParams.append('analyze_content', requestParameters.analyze_content as any);
    }

    if (requestParameters.segment_foreground !== undefined) {
      formParams.append('segment_foreground', requestParameters.segment_foreground as any);
    }

    if (requestParameters.image !== undefined) {
      formParams.append('image', requestParameters.image as any);
    }

    if (requestParameters.origin !== undefined) {
      formParams.append('origin', requestParameters.origin as any);
    }

    if (requestParameters.id !== undefined) {
      formParams.append('id', requestParameters.id as any);
    }

    const response = await this.request(
      {
        path: `/assets/new`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AssetFromJSON(jsonValue));
  }

  /**
   */
  async createAsset(
    image: Blob,
    origin: CreateAssetOriginEnum,
    analyze_content?: boolean,
    segment_foreground?: boolean,
    id?: string,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Asset> {
    const response = await this.createAssetRaw(
      { image: image, origin: origin, analyze_content: analyze_content, segment_foreground: segment_foreground, id: id },
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async createProductRaw(
    requestParameters: AssetsApiCreateProductOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Product>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/products/new`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateProductRequestToJSON(requestParameters.create_product_request),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ProductFromJSON(jsonValue));
  }

  /**
   */
  async createProduct(create_product_request?: CreateProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Product> {
    const response = await this.createProductRaw({ create_product_request: create_product_request }, initOverrides);
    return await response.value();
  }

  /**
   */
  async deleteAssetRaw(
    requestParameters: AssetsApiDeleteAssetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Ack>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling deleteAsset.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/assets/delete/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AckFromJSON(jsonValue));
  }

  /**
   */
  async deleteAsset(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Ack> {
    const response = await this.deleteAssetRaw({ id: id }, initOverrides);
    return await response.value();
  }

  /**
   */
  async findOneAssetByRaw(
    requestParameters: AssetsApiFindOneAssetByRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Asset>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/assets/find-one-asset-by`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: FindOneAssetByRequestParamsToJSON(requestParameters.find_one_asset_by_request_params),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AssetFromJSON(jsonValue));
  }

  /**
   */
  async findOneAssetBy(
    find_one_asset_by_request_params?: FindOneAssetByRequestParams,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Asset> {
    const response = await this.findOneAssetByRaw({ find_one_asset_by_request_params: find_one_asset_by_request_params }, initOverrides);
    return await response.value();
  }

  /**
   */
  async listAssetsRaw(
    requestParameters: AssetsApiListAssetsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<AssetListing>> {
    const queryParameters: any = {};

    if (requestParameters.max_items !== undefined) {
      queryParameters['max_items'] = requestParameters.max_items;
    }

    if (requestParameters.cursor_serial !== undefined) {
      queryParameters['cursor_serial'] = requestParameters.cursor_serial;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/assets`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AssetListingFromJSON(jsonValue));
  }

  /**
   */
  async listAssets(max_items?: string, cursor_serial?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AssetListing> {
    const response = await this.listAssetsRaw({ max_items: max_items, cursor_serial: cursor_serial }, initOverrides);
    return await response.value();
  }

  /**
   */
  async productSearchRaw(
    requestParameters: AssetsApiProductSearchRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ProductListing>> {
    const queryParameters: any = {};

    if (requestParameters.asset_id !== undefined) {
      queryParameters['asset_id'] = requestParameters.asset_id;
    }

    if (requestParameters.name !== undefined) {
      queryParameters['name'] = requestParameters.name;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/products/search`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ProductListingFromJSON(jsonValue));
  }

  /**
   */
  async productSearch(asset_id?: string, name?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductListing> {
    const response = await this.productSearchRaw({ asset_id: asset_id, name: name }, initOverrides);
    return await response.value();
  }

  /**
   */
  async productShowRaw(
    requestParameters: AssetsApiProductShowRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Product>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling productShow.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/products/show/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ProductFromJSON(jsonValue));
  }

  /**
   */
  async productShow(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Product> {
    const response = await this.productShowRaw({ id: id }, initOverrides);
    return await response.value();
  }

  /**
   */
  async searchAssetsRaw(
    requestParameters: AssetsApiSearchAssetsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<AssetListing>> {
    if (requestParameters.search_term === null || requestParameters.search_term === undefined) {
      throw new runtime.RequiredError('search_term', 'Required parameter requestParameters.search_term was null or undefined when calling searchAssets.');
    }

    const queryParameters: any = {};

    if (requestParameters.search_term !== undefined) {
      queryParameters['search_term'] = requestParameters.search_term;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/assets/search-assets`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AssetListingFromJSON(jsonValue));
  }

  /**
   */
  async searchAssets(search_term: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AssetListing> {
    const response = await this.searchAssetsRaw({ search_term: search_term }, initOverrides);
    return await response.value();
  }

  /**
   */
  async updateAssetRaw(
    requestParameters: AssetsApiUpdateAssetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Asset>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling updateAsset.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/assets/update/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: AssetUpdateRequestPayloadToJSON(requestParameters.asset_update_request_payload),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AssetFromJSON(jsonValue));
  }

  /**
   */
  async updateAsset(
    id: string,
    asset_update_request_payload?: AssetUpdateRequestPayload,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Asset> {
    const response = await this.updateAssetRaw({ id: id, asset_update_request_payload: asset_update_request_payload }, initOverrides);
    return await response.value();
  }
}

/**
 * @export
 */
export const CreateAssetOriginEnum = {
  UserProduct: 'user_product',
  GeneratedProp: 'generated_prop',
} as const;
export type CreateAssetOriginEnum = (typeof CreateAssetOriginEnum)[keyof typeof CreateAssetOriginEnum];
