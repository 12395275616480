import { AppBar, Button, CircularProgress, Container, Grid, Link, ListItemIcon, MenuItem, Stack, Toolbar } from '@mui/material';
import PopupState, { bindHover, bindMenu } from 'material-ui-popup-state';
import HoverMenu from 'material-ui-popup-state/HoverMenu';

import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import LABS_LIST from 'src/assets/data/labs';
import AccountPopover from 'src/layouts/dashboard/header/AccountPopover';
import Iconify from '../iconify';
import { useUploadDialogContext } from '../upload-dialog-context/UploadDialogContext';
import { useSubscriber } from './Topics';

export default function DesktopHeader() {
  const [activeApiCount, setActiveApiCount] = useState(0); //API_CALL_COMPLETE-- API_CALL_IN_PROGRESS++

  const { showUploadModal } = useUploadDialogContext();

  useSubscriber('API_CALL_IN_PROGRESS', async (_topic, _message) => {
    setActiveApiCount((count) => {
      let result = count + 1;
      return result;
    });
  });

  useSubscriber('API_CALL_COMPLETE', async (_topic, _message) => {
    setActiveApiCount((count) => {
      let result = count - 1;
      return result;
    });
  });

  return (
    <AppBar position='relative' sx={{ backgroundColor: '#ffffff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Container disableGutters>
        <Toolbar>
          <Grid container justifyContent={'space-between'}>
            <Grid item>
              <Stack direction={'row'} spacing={2}>
                <a
                  href='/'
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <img
                    src='https://res.cloudinary.com/ft-bounty/image/upload/v1684407931/app-materials/logo-icon.png'
                    width='22'
                    height='22'
                    alt='final touch'
                  />
                </a>
                <RouterLink style={{ color: 'black', fontSize: '12px', paddingTop: '3px', textDecoration: 'none' }} to='/'>
                  Home
                </RouterLink>

                <PopupState variant='popover'>
                  {(popupState) => (
                    <>
                      <RouterLink
                        style={{ color: 'black', fontSize: '12px', paddingTop: '3px', textDecoration: 'none' }}
                        to='/virtual-photoshoot'
                        {...bindHover(popupState)}
                      >
                        Virtual Photoshoot
                        <HoverMenu {...bindMenu(popupState)}>
                          <Link color="black" href="/assets/list" underline='none'>
                          <SubmenuItem
                            icon='material-symbols:camera'
                            label='Products'
                          /></Link>
                          <Link color="black" href="/creations" underline='none'>
                          <SubmenuItem
                            icon='material-symbols:photo-album'
                            label='Photoshoots'
                          />
                          </Link>
                        </HoverMenu>
                      </RouterLink>
                    </>
                  )}
                </PopupState>

                <PopupState variant='popover'>
                  {(popupState) => (
                    <>
                      <RouterLink
                        style={{ color: 'black', fontSize: '12px', paddingTop: '3px', textDecoration: 'none' }}
                        to='/#labs'
                        {...bindHover(popupState)}
                      >
                        Labs
                        <HoverMenu {...bindMenu(popupState)}>
                          {LABS_LIST.map((lab) => (
                            <Link color="black" key={lab.navigateUrl} href={lab.navigateUrl} underline='none'>
                            <SubmenuItem
                              key={lab.navigateUrl}
                              icon={lab.icon}
                              label={lab.name}
                            /></Link>
                          ))}
                        </HoverMenu>
                      </RouterLink>
                    </>
                  )}
                </PopupState>

                <PopupState variant='popover'>
                  {(popupState) => (
                    <>
                      <RouterLink
                        style={{ color: 'black', fontSize: '12px', paddingTop: '3px', textDecoration: 'none' }}
                        to='/#halloween'
                        {...bindHover(popupState)}
                      >
                        Halloween
                        <HoverMenu {...bindMenu(popupState)}>
                          <Link color="black" href="/labs/holiday-styling" underline='none'>
                          <SubmenuItem
                            icon="mdi:ghost"
                            label="Photo Styling"
                          /></Link>
                          <Link color="black" href="/labs/holiday-studio-shot" underline='none'>
                          <SubmenuItem
                            icon="mdi:camera"
                            label="Studio Photoshoot"
                          /></Link>
                          <Link color="black" href="/labs/holiday-text-to-graphic-design" underline='none'>
                          <SubmenuItem
                            icon="mingcute:pumpkin-lantern-fill"
                            label="Text to Graphic Design"
                          /></Link>
                        </HoverMenu>
                      </RouterLink>
                    </>
                  )}
                </PopupState>

                {/*<RouterLink style={{ color: 'black', fontSize: '12px', paddingTop: '3px', textDecoration: 'none' }} to='/assets'>*/}
                {/*  Products*/}
                {/*</RouterLink>*/}
                {/*<RouterLink style={{ color: 'black', fontSize: '12px', paddingTop: '3px', textDecoration: 'none' }} to='/creations'>*/}
                {/*  Photoshoots*/}
                {/*</RouterLink>*/}
                <Link sx={{ color: 'black', fontSize: '12px', pt: '3px' }} href='https://final-tou.ch/faq/' target='_blank'>
                  Help
                </Link>
              </Stack>
            </Grid>

            <Grid item>
              <Stack direction='row' spacing={2}>
                <Button variant='outlined' size='small' sx={{ fontSize: '12px' }} component='label' onClick={showUploadModal}>
                  Start With a Product Image
                </Button>
                <div style={{ marginTop: '4px' }}>
                  <CircularProgress size={24} sx={{ position: 'absolute', margin: '-2px', display: activeApiCount > 0 ? 'block' : 'none' }} color='secondary' />
                  <AccountPopover />
                </div>
              </Stack>
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

function SubmenuItem(props: {icon: string; label: string }) {
  return (
    <MenuItem
      onClick={(e) => {
        e.stopPropagation();
      }}
      sx={{ fontSize: '12px' }}
    >
      <ListItemIcon>
        <Iconify icon={props.icon} width={16} />
      </ListItemIcon>
      {props.label}
    </MenuItem>
  );
}
